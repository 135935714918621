import React, { useCallback, useReducer, useState } from 'react'

import { getPositions } from '../../components/references/lib/get-positions'
import {
  InfoBlock,
  Reference as AuthorReferenceData,
  ReferenceBlock as ReferenceBlockData,
} from '../../components/references/lib/types'
import {
  prepareInfoBlocks,
  prepareRefBlocks,
} from '../../components/rich-text/lib/format-text'

export interface ReferenceState {
  highlightedRef: number | null
  refBlocks: Array<ReferenceBlockData>
  infoBlocks: Array<InfoBlock>
  refBlock: any
  references: Array<AuthorReferenceData>
}

export interface ReferenceHandlers {
  referenceState: ReferenceState
  setHighlightedRef: (reference: number | null) => void
  onRefToggle: (event: React.MouseEvent, isCitation: boolean) => any
  updateReferences: (data: any, type?: string, filter?: boolean) => any
  scrollTo: number
  handleRefBlockClick: (event: React.MouseEvent) => void
  handleCitedWorkClick: (event: React.MouseEvent) => void
  resizeHandler: (event: any) => void
}

const initialState: ReferenceState = {
  highlightedRef: null,
  refBlocks: [] as Array<ReferenceBlockData>,
  infoBlocks: [] as Array<InfoBlock>,
  refBlock: null,
  references: [] as Array<AuthorReferenceData>,
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_HIGHLIGHTED_REF':
      return { ...state, highlightedRef: action.highlightedRef }
    case 'SET_REFERENCES':
      return { ...state, ...action.data }
    default:
      return state
  }
}

export const highlightReference = (idx) => {
  const foundReference = document.getElementById(`ref-block-${idx}`)
  foundReference?.classList.add('highlighted')
  setTimeout(() => {
    foundReference?.classList.remove('highlighted')
  }, 5000)
}

export const useReferences = (
  ref: number | null,
  options: {
    scrollDirection?: 'horizontal' | 'vertical'
  } = {}
): ReferenceHandlers => {
  const { scrollDirection = 'horizontal' } = options
  const [referenceState, setReferenceState] = useReducer(reducer, initialState)
  const [scrollTo, setScrollTo] = useState<number>(0)
  const [anchors, setAnchors] = useState<Array<any>>([])
  const [citations, setCitations] = useState<Array<any>>([])

  const onRefToggleCallback = (event, isCitationClicked) => {
    const { refIdx, refId } = onRefToggle(event, isCitationClicked)
    const target = findTargetPosition(
      refId,
      refIdx,
      isCitationClicked ? anchors : citations
    )

    if (target) {
      if (isCitationClicked) {
        highlightReference(refIdx)
      }
      setScrollTo(scrollDirection === 'horizontal' ? target.x : target.y)
    }
  }

  const handleRefBlockClick = (event) => onRefToggleCallback(event, false)
  const handleCitedWorkClick = (event) => onRefToggleCallback(event, true)

  const resizeHandler = (event) => {
    const { body } = event

    const anchorPositions: Array<any> = getPositions('ref-block-anchor', body)
    const citationPositions: Array<any> = getPositions('referrer', body)

    setAnchors(anchorPositions)
    setCitations(citationPositions)
  }

  const setHighlightedRef = (reference: number | null) => {
    setReferenceState({
      highlightedRef: reference,
      type: 'SET_HIGHLIGHTED_REF',
    })
  }

  if (ref !== null && ref !== referenceState.highlightedRef) {
    setHighlightedRef(ref)
  }
  const onUpdateHighlightClick = useCallback(
    (event: React.MouseEvent, isCitation: boolean) => {
      event?.preventDefault?.()
      event?.stopPropagation?.()
      const currentTarget = event.currentTarget as HTMLElement
      const refIdx = parseInt(currentTarget.dataset['idx'] || '')
      const refId = parseInt(currentTarget.dataset['id'] || '')
      setHighlightedRef(isCitation ? null : refId)
      return { refIdx, refId }
    },
    [setHighlightedRef]
  )

  const onRefToggle = useCallback(
    (event, isCitation = false) => {
      return onUpdateHighlightClick(event, isCitation)
    },
    [onUpdateHighlightClick]
  )

  const updateReferences = (data: any, type = '', shouldFilter = false) => {
    let infoRefBlocks
    let body = {}

    if (data) {
      const references = data.references?.map((reference) => ({
        ...reference,
        refId: reference.id,
      }))

      switch (type) {
        case 'author':
          body = data.biography
          break
        case 'article':
          body = data.body
          break
        case 'events':
          infoRefBlocks = data.reduce((acc, event) => {
            if (!event.isPlaceholder) {
              acc.push(...event.infoBlocks)
            }
            return acc
          }, [])
          body['referenceBlocks'] = data.reduce(
            (acc: Array<ReferenceBlockData>, event) => {
              if (event.refBlocks?.length > 0) {
                acc.push(...event.refBlocks)
              }
              return acc
            },
            []
          )
          break
        case 'analysis':
        case 'synopsis':
        case 'introduction':
          body = data
          infoRefBlocks = data.infoRefBlocks
          break
        default:
          // handle default case if necessary
          break
      }

      if (body) {
        const isEvent = type === 'events'
        const { referenceBlocks, info } = body as {
          referenceBlocks?: Array<ReferenceBlockData>
          info?: any
        }

        const filteredReferenceBlocks = referenceBlocks?.filter(
          (refB) => refB !== null
        )
        const preparedRefBlocks = isEvent
          ? filteredReferenceBlocks
          : prepareRefBlocks(filteredReferenceBlocks, references).blocks

        const preparedInfoBlocks = isEvent
          ? infoRefBlocks
          : prepareInfoBlocks(info || infoRefBlocks).blocks
        const filteredInfoBlocks = shouldFilter
          ? preparedInfoBlocks
          : preparedInfoBlocks.filter((o) => o?.isRef)

        const refBlockValue =
          referenceState.refBlocks?.length <= 0 && references
            ? {
                value: [...references]
                  .map(
                    ({ title, fullReference }) =>
                      `<p>${title}<br/>${fullReference}</p>`
                  )
                  .join(''),
              }
            : null

        const newState = {
          ...referenceState,
          refBlocks: preparedRefBlocks,
          infoBlocks: filteredInfoBlocks,
          refBlock: data.refs || refBlockValue,
          references: references,
        }

        setReferenceState({ data: newState, type: 'SET_REFERENCES' })
      }
    }
  }

  return {
    referenceState,
    setHighlightedRef,
    onRefToggle,
    updateReferences,
    scrollTo,
    handleRefBlockClick,
    handleCitedWorkClick,
    resizeHandler,
  }
}

export default useReferences

export const findTargetPosition = (
  refId: number,
  refIdx: number,
  position: Array<any>
) => {
  const target = position.find(
    (pos) => pos.refId === refId && pos.refIdx === refIdx
  )
  return target
}

export const withReferences = (Component: React.ComponentType<any>) => (
  props: any
) => {
  const { referenceState, onRefToggle, updateReferences } = useReferences(null)

  return (
    <Component
      referenceState={referenceState}
      updateReferences={updateReferences}
      onRefToggle={onRefToggle}
      {...props}
    />
  )
}
